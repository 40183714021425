<template>
  <div class="app-container">
    <baseTable :option="option" :api="getList">
      <template #headerBefore>
        <i class="el-icon-arrow-left cursor-pointer" style="font-size: 26px" @click="$router.back()"></i>
      </template>
      <template #menuLeft>
        <importTemplate v-if="permissionList.import" :categoryId="categoryId" :shopTypeId="shopTypeId" />
      </template>
      <template #menu="{ row }">
        <el-button v-if="permissionList.edit" type="text" size="mini" @click="onEdit(row)">编辑</el-button>

        <statusBtn v-if="permissionList.disable" :categoryId="categoryId" :data="row" :sup_this="sup_this" @success="init"></statusBtn>

        <!--<importTemplate
          v-if="permissionList.reUpload"
          type="reUpload"
          :data="row"
          :categoryId="categoryId"
          :shopTypeId="shopTypeId"
        />-->
        <loadingBtn v-if="permissionList.del" type="text" @click="onDelete(row)">删除</loadingBtn>
      </template>
    </baseTable>
  </div>
</template>

<script>
import importTemplate from './module/importTemplate'
import baseTable from '@/components/base/baseTable/mixins/baseTable'
import statusBtn from './module/statusBtn'
import templateAPi from '@/api/productTemplate/index'
import { TEMPLATE_ENABLED_OBJ } from '@/utils/constant'
import { permissionList, option } from './const'

export default {
  components: { importTemplate, statusBtn },
  mixins: [baseTable],
  data() {
    this.getList = templateAPi.templateList
    return {
      permissionList,
      option,
      TEMPLATE_ENABLED_OBJ
    }
  },
  computed: {
    resetMergeData() {
      return {
        categoryId: this.categoryId,
        templateType: 0,
        orderItems: [
          { asc: false, column: 'create_time' },
          { asc: true, column: 'id' }
        ]
      }
    },
    categoryId() {
      return this.$route.query.categoryId
    },
    shopTypeId() {
      return this.$route.query.shopTypeId
    }
  },

  methods: {
    onEdit(row) {
      this.$router.push({
        path: '/productTemplate/addTemplate',
        query: { id: row.id, shopTypeId: this.shopTypeId }
      })
    },
    async onDelete(data) {
      try {
        const res = await this.$confirm('确定删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        if (res != 'confirm') return
        const result = await awaitResolve(
          templateAPi.del({
            idList: [data.id]
          })
        )
        if (!result) return
        this.init()
        this.$message.success('删除成功')
      } catch (error) {}
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .crud-container .crud-header-before {
    margin-bottom: 0;
  }
}
</style>
